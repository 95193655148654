<div id="wrapper">

    <div id="btn-exit">
        <div class="line-1"></div>
        <div class="line-2"></div>
    </div>

    <div class="transition"></div>

    <!-- header begin -->
    <header class="transparent">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between">
                        <div class="align-self-center header-col-left">
                            <!-- logo begin -->
                            <div id="logo">
                                <a href="index.html">
                                    <img src="assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <!-- logo close -->
                        </div>
                        <div class="align-self-center ml-auto header-col-mid">
                            <!-- mainmenu begin -->
                            <ul id="mainmenu" class="scrollnav">
                                <li><a href="#section-about">About</a></li>
                                <li><a href="#section-services">Services</a></li>
                                <li><a href="#section-works">Works</a></li>
                                <li><a href="#section-news">News</a></li>
                                <li><a href="#section-contact">Contact</a></li>
                            </ul>

                            <!-- mainmenu close -->
                        </div>
                        <div class="align-self-center ml-auto header-col-right">
                            <div class="social-icons sc-plain">
                                <a href="#"><i class="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i class="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i class="fa fa-linkedin fa-lg"></i></a>
                                <a href="#"><i class="fa fa-instagram fa-lg"></i></a>
                            </div>

                            <span id="menu-btn"></span>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- header close -->
    <!-- content begin -->
    <div class="no-bottom no-top" id="content">
        <div id="top"></div>

        <!-- section begin -->
        <section id="section-main" class="full-height text-light no-padding">
            <div class="de-video-container">
                <div class="de-video-content">
                    <div class="container">
                        <div class="row wow fadeIn" data-wow-delay=".3s">
                            <div class="col-lg-10 offset-lg-1 text-center">
                                <h2>We Love The <span class="id-color">Opportunity</span> and <span class="id-color">To Work With You.</span>  Because You are choosing Us!</h2>
                            </div>
                            <div class="col-lg-6 offset-lg-3 text-center">
                                <p>We make what our customer need. We create a design which our customer like. Building on belief.</p>
                                <div class="spacer-10"></div>
                                <a class="btn-custom scroll-to text-dark" href="#section-about">Who We Are</a>&nbsp;&nbsp;
                                <a class="btn-custom btn-border-light scroll-to" href="#section-services">What We Do</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="de-video-overlay"></div>

                <!-- load your video here -->
                <video autoplay="" loop="" muted="" poster="assets/images/video-poster.jpg">
                    <source src="assets/videos/video.mp4" type="video/mp4" />
                </video>

            </div>
        </section>
        <!-- section close -->

        <section id="section-about" class="vertical-center dark text-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center wow fadeInDown">
                        <h2 class="title">About <span class="id-color">Us</span></h2>
                    </div>
                </div>

                <div class="row wow fadeInUp">
                    <div class="col-lg-6">
                        <blockquote class="s1 c1">
                            <span class="b-text">Design is not just what it looks like and feels like. It all starts here.</span>
                            <span class="b-arrow"></span>
                        </blockquote>
                    </div>

                    <div class="col-lg-6">
                        <p>
                            Every business is born out of belief. The belief you can make an impact. That you have an idea that matters. 
                            At Vinmasscode we go beyond helping businesses transform through technology. We help them make a meaningful difference. 
                            Translating their inspiration into reality.  
                        </p>
                    </div>
                </div>

                <div class="spacer-double"></div>

                <div class="row wow fadeInUp">
                    <div class="col-md-12 mb30">
                    <img class="img-fluid" src="assets/images/misc/1.jpg" alt=""/>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 mb-sm-30">
                        <div class="f-profile text-center">
                            <div class="fp-wrap f-invert">
                                <div class="fpw-overlay">
                                    <div class="fpwo-wrap"></div>
                                </div>
                                <div class="fpw-overlay-btm"></div>
                                <img src="assets/images/team/1.jpg" class="fp-image img-fluid" alt="">
                            </div>
                            <h4>Manish Misra</h4>
                            Founder &amp; CEO

                            <div class="fpwow-icons">
                                <a href="#"><i class="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i class="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i class="fa fa-linkedin fa-lg"></i></a>
                                <a href="#"><i class="fa fa-pinterest fa-lg"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-sm-30">
                        <div class="f-profile text-center">
                            <div class="fp-wrap f-invert">
                                <div class="fpw-overlay">
                                    <div class="fpwo-wrap"></div>
                                </div>
                                <div class="fpw-overlay-btm"></div>
                                <img src="assets/images/team/2.jpg" class="fp-image img-fluid" alt="">
                            </div>
                            <h4>Aman Chaubey</h4>
                            Lead Developer

                            <div class="fpwow-icons">
                                <a href="#"><i class="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i class="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i class="fa fa-linkedin fa-lg"></i></a>
                                <a href="#"><i class="fa fa-pinterest fa-lg"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-sm-30">
                        <div class="f-profile text-center">
                            <div class="fp-wrap f-invert">
                                <div class="fpw-overlay">
                                    <div class="fpwo-wrap"></div>
                                </div>
                                <div class="fpw-overlay-btm"></div>
                                <img src="assets/images/team/3.jpg" class="fp-image img-fluid" alt="">
                            </div>
                            <h4>Esha Jean</h4>
                            Lead Designer

                            <div class="fpwow-icons">
                                <a href="#"><i class="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i class="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i class="fa fa-linkedin fa-lg"></i></a>
                                <a href="#"><i class="fa fa-pinterest fa-lg"></i></a>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="spacer-double"></div>

               <!---- <div class="row wow fadeInUp">
                    <div class="col-md-12 text-center">
                        <h3>Testimonials</h3>
                    </div>

                    <div class="col-md-12">
                        <div id="testimonial-carousel" class="owl-carousel owl-theme">
                            <blockquote class="testimonial-big">
                                I'm always impressed with the services. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip.
                                <img src="assets/images/people/1.jpg" alt="" />
                                <span class="name">John, Customer</span>
                            </blockquote>

                            <blockquote class="testimonial-big">                                        
                                I'm always impressed with the services. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip.
                                <img src="assets/images/people/2.jpg" alt="" />
                                <span class="name">Sandra, Customer</span>
                            </blockquote>

                            <blockquote class="testimonial-big">
                                I'm always impressed with the services. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip.
                                <img src="assets/images/people/3.jpg" alt="" />
                                <span class="name">Michael, Customer</span>
                            </blockquote>

                            <blockquote class="testimonial-big">                                        
                                I'm always impressed with the services. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip.
                                <img src="assets/images/people/4.jpg" alt="" />
                                <span class="name">George, Customer</span>
                            </blockquote>
                        </div>
                    </div>
                </div>-->

            </div>
        </section>
        
        <section id="section-services" class="vertical-center dark text-light">
        <div class="container">
            <div class="row wow fadeInDown">
                <div class="col-lg-12 text-center">
                    <h2 class="title"><span class="id-color">Our</span> Services</h2>
                </div>
            </div>

            <div class="row wow fadeInUp">
                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-laptop bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>Website Design</h4>
                            <p>The main aim of dynamic website designing is to provide 
                                online services to the visitors.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-paper-plane-o bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>Content Writing</h4>
                            <p>“Innovate. Create. Transform.” This is why we have created Vinmasscode. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-line-chart bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>E-Commerce</h4>
                            <p>Having a robust e-commerce website is the key to success for entrepreneurs, and raise profit.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-object-group bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>CMS Development</h4>
                            <p>We give scalable solutions and the right to be creative with a design using CMS development.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-hdd-o bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>Backup &amp; Security</h4>
                            <p>We provide domain support to client website hosting. You name it,we got it covered.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mb30">
                    <div class="f-box f-icon-left f-icon-box f-border">
                        <i class="fa fa-comments-o bg-color text-dark"></i>
                        <div class="fb-text">
                            <h4>IT Consultancy</h4>
                            <p>We provide consultancy service for all those who need us in any way. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <!-- section begin -->
        <section id="section-works" class="vertical-center dark text-light">
            <div class="container">
                <!-- portfolio filter begin -->
                <div class="row">
                    <div class="col-lg-12 text-center wow fadeInDown">
                        <h2 class="title"><span class="id-color">Our</span> Works</h2>
                    </div>

                    <div class="col-md-12">
                        <div class="zoom-gallery row sequence">
                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/1.jpg" title="Project One" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project One</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/1.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/2.jpg" title="Project Two" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project Two</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/2.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/3.jpg" title="Project Three" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project Three</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/3.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/4.jpg" title="Project Four" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project Four</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/4.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/5.jpg" title="Project Five" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project Five</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/5.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                            <!-- gallery item -->
                            <div class="col-md-4 mb30 gallery-item">
                                <div class="pf_item wow">    
                                    <div class="picframe">
                                        <a href="assets/images/portfolio/6.jpg" title="Project Six" data-source="http://themeforest.net/user/designesia">
                                            <span class="overlay">
                                                <span class="pf_text">
                                                    <span class="project-name">Project Six</span>
                                                </span>
                                            </span>
                                            <img src="assets/images/portfolio/6.jpg" alt="" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- close gallery item -->

                        </div>
                    </div>
                    <!-- portfolio filter close -->
                </div>
            </div>
        </section>
        <!-- section close -->

        <!-- section begin -->
        <section id="section-news" class="vertical-center dark text-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center wow fadeInDown">
                        <h2 class="title"><span class="id-color">Our</span> News</h2>
                    </div>
                </div>

                <div class="row wow fadeInUp">
                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/1.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">20</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">Better User Interface</a></h4>
                                    <p>Sint commodo ut excepteur esse in dolor est pariatur minim aliqua esse incididunt minim amet exercitation.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/2.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">19</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">Experts Web Design Tips</a></h4>
                                    <p>Exercitation velit dolor consequat enim veniam nulla dolor sunt laborum non magna ad veniam consequat minim.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/3.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">18</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">Importance Of Web Design</a></h4>
                                    <p>Excepteur ut commodo incididunt ut in commodo voluptate anim do elit occaecat commodo ut ea sint sunt.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/4.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">17</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">Avoid These Erros In UI Design</a></h4>
                                    <p>Occaecat dolor dolore ut ut sint deserunt dolore incididunt mollit anim magna fugiat et ex fugiat anim esse ea.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/5.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">16</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">Make Mobile Website Faster</a></h4>
                                    <p>Lorem ipsum eiusmod eiusmod aliquip tempor duis in dolor id labore sed commodo magna qui quis dolor eu.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb30">
                        <div class="bloglist item">
                            <div class="post-content">
                                <div class="post-image">
                                    <img alt="" src="assets/images/news/6.jpg">
                                </div>
                                <div class="date-box">
                                    <div class="d">15</div>
                                    <div class="m">Dec</div>
                                </div>
                                <div class="post-text">
                                    <h4><a href="#">How Sell Digital Product</a></h4>
                                    <p>Veniam ut ex sed dolor eiusmod labore in enim elit fugiat eu proident minim ut cillum aliquip amet eiusmod.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- section begin -->

        <section id="section-contact" class="vertical-center dark text-light">
        <!-- section begin -->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center wow fadeInDown">
                        <h2 class="title">Contact <span class="id-color">Us</span></h2>
                    </div>
                </div>

                <form name="contactForm" id="contact_form" class="row form-default form-dark wow fadeInUp" data-wow-delay=".25s" method="post" action="email.php">
                    <div class="col-md-6">
                        <div class="field-set"> 
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name" />
                            <div class="line-fx"></div>
                        </div>

                        <div class="field-set">
                            <input type="text" name="email" id="email" class="form-control" placeholder="Your Email" />
                            <div class="line-fx"></div>
                        </div>

                        <div class="field-set">
                            <input type="text" name="phone" id="phone" class="form-control" placeholder="Your Phone" />
                            <div class="line-fx"></div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <textarea name="message" id="message" class="form-control" placeholder="Your Message"></textarea>
                            <div class="line-fx"></div>
                        </div>
                    </div>

                    <div class="spacer-single"></div>

                    <div class="col-md-12 text-center">
                        <div id="submit">
                            <input type="submit" id="send_message" value="Submit Form" class="btn btn-custom text-dark" />
                        </div>
                        <div id="mail_success" class="success">Your message has been sent successfully.</div>
                        <div id="mail_fail" class="error">Sorry, error occured this time sending your message.</div>
                    </div>
                </form>
            </div>
        </section>
    </div>
    <!-- content close -->

</div>